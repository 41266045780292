import { types } from "../types/types";

const initialState = {
   english: true
}

export const languageReducer = ( state = initialState, { type , payload }) =>{

   switch (type) {
      case types.ENGLISH:
         
         return {
            ...state,
            english: true
         }

      case types.SPANISH:
         return{
            ...state,
            english: false
         }
   
      default:
         return state;
   }


}