import { Facebook, Instagram, LinkedIn } from '@mui/icons-material'
import React from 'react'

export const Footer = () => {
   return (
      <footer>
         <a href="https://www.linkedin.com/company/aya-natural-products/mycompany/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
               <LinkedIn />
            </a>
            <a href="https://www.instagram.com/ayanaturalproductsec/" target="_blank" rel="noopener noreferrer">
               <Instagram />
            </a>
            <a href="https://www.facebook.com/ayanaturalproductsec" target="_blank" rel="noopener noreferrer">
               <Facebook />
            </a>
      </footer>
   )
}
