import { useSelector } from "react-redux";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Products } from "./components/Products";
import { SectionTitle } from "./components/SectionTitle";
import { Slider } from "./components/Slider";
import { data } from './data/data';

const images = require.context('./assets/images', true );

const App = () => {

  const isEnglish = useSelector( state => state.english );
  const info = isEnglish ? data.en : data.es;  
  return (
    <div>
      <Header isEnglish={ isEnglish } images={ images } />
      <Slider isEnglish={ isEnglish } images={ images } />
      <SectionTitle title={ info.titleProducts } />
      <Products categoryProduct={ info.sinchiProducts } images={ images } className="lightBrown" />
      <Products categoryProduct={ info.samyProducts } images={ images }  />
      <About about={ info.aboutSection } images={ images } />
      <SectionTitle title={ info.titleCONTACT } />
      <Contact contact={ info.contactSection } />
      <Footer />
    </div>
  );
}

export default App;
