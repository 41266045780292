import React from 'react';
import {Link} from 'react-scroll'

export const About = ( {about, images} ) => {
  return (
    <div className='aboutContainer'>
       <div className="container">
         <div className="textContainer">
            <h2>
               { about.title }
            </h2>
            <p>
               { about.desc }
            </p>
            <Link to="contact" spy={true} smooth={true} offset={-200} duration={500}>
               { about.button }
            </Link>
         </div>
         <div className="imageContainer">
            <img src={ images( `./${about.img}` ) } alt={about.title} />
         </div>
       </div>
    </div>
  )
}
