import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { english, spanish } from "../actions/languajeActions";


import { data } from '../data/data';

export const Header = ( { isEnglish, images } ) => {

   const dispatch = useDispatch();

   const handleEn = ()=>{
      dispatch( english() )
   }
   const handleEs = ()=>{
      dispatch( spanish() )
   }

   const info = isEnglish ? data.en : data.es;  
  return (
   <div>
      <div className="preheader">
         {
         isEnglish 
         ? <h1 className="center">{info.preheader}</h1>
         : <h1 className="center">{info.preheader}</h1>
         }
      </div>
      <header className="container">
         <div className="logo">
            <img src={ images( './01-logo-aya.png' )} alt="" />
         </div>
         <nav>
            <a href="https://www.linkedin.com/company/aya-natural-products/mycompany/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
               <LinkedIn />
            </a>
            <a href="https://www.instagram.com/ayanaturalproductsec/" target="_blank" rel="noopener noreferrer">
               <Instagram />
            </a>
            <a href="https://www.facebook.com/ayanaturalproductsec" target="_blank" rel="noopener noreferrer">
               <Facebook />
            </a>
            <button onClick={ handleEn }>EN</button>
            <button onClick={ handleEs }>ES</button>
         </nav>
      </header>
   </div>
  )
}
