import { useState } from "react"


export const useForms = (initialState={}) => {
   
   const [values, setValues] = useState(initialState);

   const reset = ( newFormState = initialState)=>{
      setValues(newFormState)
   }

   const handleInputChange = ({target})=>{
      setValues({
         ...values,
         [target.name]: target.value
      })
   }

   return [ values, handleInputChange, reset];
}
