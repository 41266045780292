import React from 'react';

export const SectionTitle = ( { title } ) => {


   return (
      <div className='section-title'>
         <h2 className='center'>{ title }</h2>
      </div>
   )
}
