import React, { useEffect } from 'react';
import { data } from '../data/data';
import { Link } from 'react-scroll'

export const Slider = ( { isEnglish, images } ) => {


  const info = isEnglish ? data.en : data.es;  
  
  const { slides } = info;

  useEffect(() => {
    let slideIndex = 0;
    
    const showSlides =() => {
      let i;
      let slides = document.getElementsByClassName("slide");
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      slideIndex++;
      if (slideIndex > slides.length) {slideIndex = 1}    
      
      slides[slideIndex-1].style.display = "block";  
      
      setTimeout(showSlides, 11000); // Change image every 2 seconds
    }
    showSlides();
  }, [])
  

  return (
    <div className="slideshow-container">
        {
          slides?.map( slide => (
            <div className="slide fade" key={ slide.img }  >
              <img src={ images( `./${slide.img}` ) } alt="" />
              <div className="text-container">
                <h2>{ slide.title }</h2>
                <p>{ slide.desc }</p>
                <Link to="contact" spy={true} smooth={true} offset={-200} duration={500}>{ slide.button }</Link>
              </div>
            </div>
          ) )
        }
    </div>
  )
}

// style={ { backgroundImage:`url( ${ images( `./${slide.img}` ) } )` } }