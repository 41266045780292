import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { Send } from '@mui/icons-material';
import { useForms } from '../hooks/useForm';

export const Contact = ( { contact } ) => {

   const form = useRef();
   const [ values, handleInputChange, reset ] = useForms({
      names:'',
      email:'',
      country:'',
      comments:''
   })

   const { names, email, country, comments } = values;

   const handleSendForm = (e)=>{
      e.preventDefault();
      emailjs.sendForm('service_qubss5e', 'template_cjpt3aw', form.current, 'kiG1j8y0FLOEEYO-J')
      .then((result) => {
          console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: 'Hurrah...',
            text: 'Thanks for contacting us!',
          })
      }, (error) => {
          console.log(error.text);
      });
      reset();
   }
   return (
   <div className='contact' id="contact"> 
      <div className="container">
         <form onSubmit={ handleSendForm } ref={form}>
            <div className="inputContainer">
               <label htmlFor="name">{contact.names}</label>
               <input type="text" name="names" id="name" value={names} onChange={ handleInputChange } required/>
            </div>
            <div className="inputContainer">
               <label htmlFor="email">{contact.email}</label>
               <input type="email" name="email" id="email" value={email} onChange={ handleInputChange } required/>
            </div>
            <div className="inputContainer">
               <label htmlFor="country">{contact.country}</label>
               <input type="country" name="country" id="country"value={country} onChange={ handleInputChange } required/>
            </div>
            <div className="inputContainer">
               <label htmlFor="comments">{contact.comments}</label>
               <textarea name="comments" id="comments" cols="30" rows="10" value={comments} onChange={ handleInputChange } ></textarea>
            </div>
            <button type="submit" >{contact.button} <Send /></button>
         </form>
      </div>  
   </div>
   )
}
