export const data = {
   en : {
      preheader : 'NATURAL PRODUCTS',
      slides: [
         {
            id: 1,
            title: 'AYA NATURAL PRODUCTS',
            desc: 'AYA Natural Products, is an association created by the necessity of investigation and harness the benefits of natural plants.',
            img: '02-slide-1.jpg',
            button:'CONTACT US'
         },
         {
            id:2,
            title: 'AYA NATURAL PRODUCTS',
            desc: 'We have innovate on development of products that combines bioactive medical cannabis with a long diversity of plants found in Ecuador.',
            img: '02-slide-2.jpg',
            button:'CONTACT US'
         },
      ],
      titleProducts:'OUR PRODUCTS',
      titleCONTACT:'CONTACT US',
      sinchiProducts:{
         logo: '08-sinchi-logo.png',
         categoryName: 'Shinchi Therapeutic Natural',
         products: [
            {
               id: 1,
               title:'Cannabis and sacha inchi oil 500 mg.',
               img:'04-canabis-sacha-inchi-oil-500.jpg',
               premium: 'PREMIUM',
               button:'MORE INFO'
            },
            {
               id: 2,
               title:'Cannabis and sacha inchi oil 1000 mg.',
               img:'05-canabis-sacha-inchi-oil-1000.jpg',
               premium: 'PREMIUM',
               button:'MORE INFO'
            },
            {
               id: 3,
               title:'Cannabis and sacha inchi oil 1500 mg.',
               img:'06-canabis-sacha-inchi-oil-1500.jpg',
               premium: 'PREMIUM',
               button:'MORE INFO'
            },
            {
               id: 4,
               title:'Cannabis, romero and sacha inchi ointment',
               img:'07-canabis-sacha-inchi-ointment.jpg',
               premium: false,
               button:'MORE INFO'
            },
         ]
      },
      samyProducts:{
         logo: '09-samy-logo.png',
         categoryName: 'Samy Beauty Natural Skincare',
         products: [
            {
               id: 1,
               title:'Cannabis and sacha inchi solid shampoo. Eucalyptus fragance.',
               img:'10-shampoo-eucalipto.jpg',
               premium: 'DOUBLE PACK',
               button:'MORE INFO'
            },
            {
               id: 2,
               title:'Cannabis and sacha inchi solid shampoo. Lavender fragance.',
               img:'11-shampoo-lavanda.jpg',
               premium: 'DOUBLE PACK',
               button:'MORE INFO'
            },
            {
               id: 3,
               title:'Cannabis and sacha inchi solid shampoo. Orange fragance.',
               img:'12-shampoo-naranja.jpg',
               premium: 'DOUBLE PACK',
               button:'MORE INFO'
            },
            {
               id: 4,
               title:'Micellar water. Lavender Fragance.',
               img:'13-agua-micelar-lavanda.jpg',
               premium: false,
               button:'MORE INFO'
            },
         ]
      },
      aboutSection:{
         title:'COMMITTED TO DEVELOPMENT',
         desc:'Our company work continuously on an integral proposal of social responsibility, including fundamental topics like investigation and education about plants properties, participation on our productive proccess with ancient people and small productors, and the enviroment preservation through production proccesses.',
         img:'15-about-img.png',
         bg: '14-about-bg.jpg',
         button: 'CONTACT US'
      },
      contactSection:{
         names:'Names',
         email:'Email',
         country:'Country',
         comments:'Comments',
         button:'Send'
      }
   },


   es : {
      preheader : 'PRODUCTOS NATURALES',
      slides: [
         {
            id: 1,
            title: 'AYA NATURAL PRODUCTS',
            desc: 'AYA Natural Products, es una asociación creada por la necesidad de investigación y aprovechamiento de los beneficios de las plantas naturales.',
            img: '02-slide-1.jpg',
            button:'CONTÁCTANOS'
         },
         {
            id:2,
            title: 'AYA NATURAL PRODUCTS',
            desc: 'Hemos innovado en el desarrollo de productos que combinan bioactivos del cannabis medicinal con diversas plantas de poder encontradas en el Ecuador.',
            img: '02-slide-2.jpg',
            button:'CONTÁCTANOS'
         },
      ],
      titleProducts:'NUESTROS PRODUCTOS',
      titleCONTACT:'CONTACTANOS',
      sinchiProducts:{
         logo: '08-sinchi-logo.png',
         categoryName: 'Shinchi Therapeutic Natural',
         products: [
            {
               id: 1,
               title:'Aceite de cannabis y sacha inchi de 500 mg.',
               img:'04-canabis-sacha-inchi-oil-500.jpg',
               premium: 'PREMIUM',
               button:'MÁS INFORMACIÓN'
            },
            {
               id: 2,
               title:'Aceite de cannabis y sacha inchi de 1000 mg.',
               img:'05-canabis-sacha-inchi-oil-1000.jpg',
               premium: 'PREMIUM',
               button:'MÁS INFORMACIÓN'
            },
            {
               id: 3,
               title:'Aceite de cannabis y sacha inchi de 1500 mg.',
               img:'06-canabis-sacha-inchi-oil-1500.jpg',
               premium: 'PREMIUM',
               button:'MÁS INFORMACIÓN'
            },
            {
               id: 4,
               title:'Pomada de cannabis, romero y sacha inchi',
               img:'07-canabis-sacha-inchi-ointment.jpg',
               premium: false,
               button:'MÁS INFORMACIÓN'
            },
         ]
      },
      samyProducts:{
         logo: '09-samy-logo.png',
         categoryName: 'Samy Beauty Natural Skincare',
         products: [
            {
               id: 1,
               title:'Shampoo Sólido de cannabis y sacha inchi aroma eucalipto.',
               img:'10-shampoo-eucalipto.jpg',
               premium: 'DOUBLE PACK',
               button:'MÁS INFORMACIÓN'
            },
            {
               id: 2,
               title:'Shampoo Sólido de cannabis y sacha inchi aroma lavanda.',
               img:'11-shampoo-lavanda.jpg',
               premium: 'DOUBLE PACK',
               button:'MÁS INFORMACIÓN'
            },
            {
               id: 3,
               title:'Shampoo Sólido de cannabis y sacha inchi aroma naranja.',
               img:'12-shampoo-naranja.jpg',
               premium: 'DOUBLE PACK',
               button:'MÁS INFORMACIÓN'
            },
            {
               id: 4,
               title:'Agua micelar lavanda.',
               img:'13-agua-micelar-lavanda.jpg',
               premium: false,
               button:'MÁS INFORMACIÓN'
            },
         ]
      },
      aboutSection:{
         title:'COMPROMETIDOS CON EL DESARROLLO',
         desc:'Nuestra empresa trabaja continuamente en una propuesta integral de responsabilidad social, que incluye aspectos fundamentales como la investigación y educación sobre las propiedades de las plantas, la participación dentro de nuestra cadena productiva de los pueblos ancestrales y pequeños productores, y la preservación del medio ambiente a través de los procesos de producción.',
         img:'15-about-img.png',
         bg: '14-about-bg.jpg',
         button: 'CONTÁCTANOS'
      },
      contactSection:{
         names:'Nombres',
         email:'Email',
         country:'País',
         comments:'Comentarios',
         button:'Enviar'
      }
   }
}