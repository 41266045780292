import { types } from "../types/types";

export const english = ()=>(
   {
      type: types.ENGLISH
   }
)

export const spanish = ()=>(
   {
      type: types.SPANISH
   }
)