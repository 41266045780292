import React from 'react';
import { Link } from 'react-scroll';

export const Products = ( { categoryProduct , images, className } ) => {
   

   return (
      <div className={`productsContainer ${ className }`}>
         <img src={ images( `./${ categoryProduct.logo }`) } alt={categoryProduct.categoryName} className="categoryLogo"/>
         <div className="products">
            {
               categoryProduct.products.map( product => (
                  <div className="product" key={product.id}>
                     <div className="imgContainer">
                        <img src={ images( `./${product.img}` ) } alt={product.title} />
                        {
                           product.premium &&
                           <p>{product.premium}</p>
                        }
                     </div>
                     <div className="textContainer">
                        <p className="center">
                           {product.title}
                        </p>
                        <Link to="contact" spy={true} smooth={true} offset={-200} duration={500}>{ product.button }</Link>
                     </div>
                  </div>
               ) )
            }
         </div>
      </div>
   )
}
